import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QualificationTypesService } from 'src/app/admin/Setup/qualification-types/qualification-types.service';
import { environment } from 'src/environments/environment';
import { AdvisorQualificationService } from '../advisor-qualification.service';

export const BASE_URL: string = environment.BASE_URL;
@Component({
  selector: 'app-advisor-qualification-dialog',
  templateUrl: './advisor-qualification-dialog.component.html',
  styleUrls: ['./advisor-qualification-dialog.component.scss']
})

export class AdvisorQualificationDialogComponent implements OnInit {

  qualificationTypes = [];
  viewSubmitted: boolean;

  constructor(
   public advisorQualificationService: AdvisorQualificationService,
   public qualificationTypeService: QualificationTypesService,
    private dialogRef: MatDialogRef<AdvisorQualificationDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){ }

  ngOnInit() {
    this.getQualificationTypes();
    if(this.router.url.split('/')['2'] == 'submitted-applications') {
      this.viewSubmitted = true
    } else {
      this.viewSubmitted = false;
    }     
    if(!this.advisorQualificationService.form.get('id').value) {
    this.advisorQualificationService.form.get('qualificationable_id').setValue(this.data.id);
    }else{
    this.advisorQualificationService.form.get('attachment').setValue(this.data.id.attachment);
    }
    this.advisorQualificationService.form.get('category').setValue(this.data.category);
  }

   submitForm() {
    const data = this.advisorQualificationService.form.value;
    if (this.advisorQualificationService.form.valid) {
      const newData = {
        ...data,
      //  category: 'Advisor',
        attachment: data.attachment.id
      }
      if (!this.advisorQualificationService.form.get('id').value) {
        this.advisorQualificationService.create(data).subscribe(response => {
          this.onClose();
        });
        console.log(data);
      } else {
        this.advisorQualificationService.update(data).subscribe(data => {
          console.log(data);
          
          this.onClose();
        });
      }
      this.onClose();
    }
  }
 
  onClose() {
    this.advisorQualificationService.form.reset();
    this.advisorQualificationService.initializeFormGroup();
    this.dialogRef.close();
    this.dialogRef.afterClosed()
      .subscribe(response => {
      });
  }

  onReset() {
    this.advisorQualificationService.form.reset();
    this.advisorQualificationService.initializeFormGroup();
  }

 public getQualificationTypes() {
    this.qualificationTypeService.get().subscribe(res => {
        this.qualificationTypes = res.data;
    });
} 

fileUploadFn(attachment: any) {
  this.advisorQualificationService.form.patchValue({ attachment });
}

removeFile() {
  this.advisorQualificationService.form.patchValue({ attachment: null });
}

getFilePath(resourceId) {
  return BASE_URL + '/file-resources/download/' + resourceId;
}

}
