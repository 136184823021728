<div fxLayout="row" fxLayoutAlign="space-between" class="mat-dialog-header">
    <div class="header-title align-self-center" style="margin-top: 2.5%;">
        <h2>Upload Company Attachments</h2>
    </div>
    <div class="header-title align-self-center">
      <button (click)="onClose()" mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
<div class=""
         fxLayout="row"
         fxLayout.xs="column"
         fxFlexFill
         fxLayoutAlign="center center">
  <form [formGroup]="attachmentService.form"
        class="form"
        fxFlex="500px"
        (ngSubmit)="submitForm()">
    
    <mat-divider></mat-divider> 
    <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="5px">
        <mat-form-field appearance="outline">
            <mat-label>Select Attachment</mat-label>
              <mat-select (selectionChange)="onSelectEvent($event)" formControlName="document_id" >
                <mat-option *ngFor="let document of documents" [value]="document.id" >
                  {{document.document_name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
      </div>

      <div *ngIf="visible" class="dialog-content" fxLayout="column" fxLayoutGap="5px">
        <app-file-upload *ngIf="!attachmentService.form.controls['attachment'].value"
            title="Upload Your File" fileResourceTypeName="testfile"
            (complete)="fileUploadFn($event)"> 
        </app-file-upload>
        <small *ngIf="!attachmentService.form.controls['attachment'].value" color="warn"><strong>Upload Your File</strong> (Max: 5MB)</small>
        <small *ngIf="attachmentService.form.controls['attachment'].value" color="warn"><strong>{{attachmentService.form.controls['attachment'].value?.fileName }}</strong></small>
        <div *ngIf="attachmentService.form.controls['attachment'].value" fxLayout="column">
            <div>
                <div fxFlex="40%" style="padding-top: 0.7%">
                    <a target="_blank" matTooltip="Preview this File"
                    [href]="getFilePath(attachmentService.form.controls['attachment'].value?.id)">
                    <mat-icon>attachment</mat-icon><span><h4>Preview</h4></span>
                    </a>
                </div>
                <div fxFlex="4%">
                    <span style="cursor: pointer;" matTooltip="Remove this file" mat-icon-button (click)="removeFile()">
                        <mat-icon color="warn">cancel</mat-icon>
                    </span>
                </div>
            </div>
          
        </div>
      </div>

      <div *ngIf="visible && doc!==null" class="dialog-content" fxLayout="column" fxLayoutGap="5px">
        <mat-form-field appearance="outline">
          <mat-label>Enter Attachment Description</mat-label>
          <textarea matInput
                   formControlName="description"
                   placeholder="Enter information about attachment"></textarea>
                   <mat-hint>{{docInfo}}</mat-hint>
        </mat-form-field>
        </div>

      <!--<pre>{{ data | json }}</pre>--> 
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end stretch">
      <div class="button">
        <button mat-raised-button type="button" (click)="onReset()">Reset</button>
        <button mat-raised-button color="primary" [disabled]="attachmentService.form.invalid" type="submit">
          SUBMIT
        </button>
      </div>
    </mat-dialog-actions> 
  </form>
</div>



