import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { environment } from 'src/environments/environment';
import { SubmittedApplicationService } from '../../submitted-application/submitted-application.service';
import { AttachmentService } from './attachment.service';
import { AttachmentDialogComponent } from './modal/attachment-dialog.component';

export const BASE_URL: string = environment.BASE_URL;

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {

  form: FormGroup;
  attachments: any = [];
  attachmentId: number;
  viewSubmitted: boolean;
  changeDetail: boolean;
  formAction: string;
  profile: boolean;
  attachment = false;
  companyId: number;

  constructor(
      private attachmentService: AttachmentService,
      private taostrService: ToastrService,
      private dialog: MatDialog,
      private service: SubmittedApplicationService,
      private router: Router
    ){ }
    
  displayedColumns: string[];
  dataSource: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('deleteDialog', {static: false}) deleteDialog: TemplateRef<any>;


  ngOnInit() {
      this.getAttachments();  
      if(this.router.url.split('/')['2'] == 'submitted-applications' || this.router.url.split('/')['2'] == 'view-licenses') {
        this.viewSubmitted = true
        this.companyId = +this.router.url.split('/')['3'];
      } else {
        this.viewSubmitted = false;
      }
      if(this.router.url.split('/')['2'] == 'change-detail') {
        this.changeDetail = true
      } else {
        this.changeDetail = false;
      }
      if(this.router.url.split('/')['2'] == 'company-registration-detail') {
        this.profile = true;
        this.displayedColumns = ['No', 'attachment_name', 'description', 'attachment'];
      } else {
        this.profile = false;
        this.displayedColumns = ['No', 'attachment_name', 'description', 'attachment', 'formActions'];
      }
      this.form = new FormGroup({
        id        : new FormControl(''),
        is_analyzed : new FormControl(false),
      });
  }

  public review() {
    const data = {
      is_analyzed: !this.form.value.is_analyzed,
      analyse_type: "attachment",
      company_id: +this.router.url.split('/')['3']
    }
    this.service.analyze(data).subscribe(res => {
      this.getAttachments();
    })
  }

  public getAttachments() {
    if(this.router.url.split('/')['2'] == 'submitted-applications' || this.router.url.split('/')['2'] == 'view-licenses') {
      const companyId: number = +this.router.url.split('/')['3'];
      this.attachmentService.find(companyId).subscribe(res => {
          this.form.patchValue({is_analyzed: res.data[0].is_analyzed});
          this.attachments = res.data;
          this.dataSource = new MatTableDataSource<any>(res.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      })
    } else {
      this.attachmentService.get().subscribe(res => {
        console.log(res.data);
          this.attachments = res.data;
          this.dataSource = new MatTableDataSource<any>(res.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      })
    }
  }


  openDialog(data?: any): void {
    const config = new MatDialogConfig();
    config.data = data;
    // config.width = '45%';
    config.position = {
      top: '90px',
    };
    config.panelClass = 'mat-dialog-box';
    config.backdropClass = 'mat-dialog-overlay';
    config.disableClose = false;
    config.autoFocus = false;

    if (data) {
      this.dialog.open(AttachmentDialogComponent, config)
      .afterClosed().subscribe(() => {
        this.getAttachments();
      });
    } else {
      config.data = {};
      this.dialog.open(AttachmentDialogComponent, config)
        .afterClosed().subscribe(() => {
          this.getAttachments();
        });
    }
  }

  delete(id: number, attachment: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {'title': 'Deleting Confirmation', 'message': 'Are you sure you want delete ' + attachment.gfs_code},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.attachmentService.delete(id).subscribe({
          next: () => {
            this.taostrService.success(
              'Success',
              'Attachment Deleted Successfully!'
            );
            this.getAttachments();
          },
          
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource .filter = filterValue.trim().toLowerCase();
  }

  getFilePath(resourceId) {
    return BASE_URL + '/file-resources/download/' + resourceId;
  }
  
}
