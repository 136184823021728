// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { appConfig as config } from '../../.env';

export const environment = {
  production: false,
  BASE_URL      : config.BASE_URL,
  API_BASE_URL      : config.API_BASE_URL,
  API_BASE_URL2      : config.API_BASE_URL2,
  API_BASE_URL3      : config.API_BASE_URL3,
  TIRA_USER   : 'tira-user',
  USER : 'user',
  url: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
