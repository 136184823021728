import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';



export const BASE_URL: string = environment.BASE_URL;
export const RESOURCE_URL = 'qualification-type';

@Injectable({
  providedIn: 'root'
})
export class QualificationTypesService {

  qualificationtypes: Observable<any>;
  qualificationtypesSubject: BehaviorSubject<any>;

  private API_ENDPOINT = `${BASE_URL}/${RESOURCE_URL}`;


  constructor(

     private httpClient: HttpClient,
     
     ) { 

      this.qualificationtypesSubject = new BehaviorSubject<any>(this.get());
      this.qualificationtypes = this.qualificationtypesSubject.asObservable();
}

form = new FormGroup({
  id        : new FormControl(''),
  qualification_type_name : new FormControl('', [Validators.required]),
  
});

/**
 * helper function to extract data since
 * we are not using a type checker in the request
 * @returns Observable
 *
 * @param res
 */
private static extractData(res: Response) {
  return res || {};
}

/**
 * get the list of all qualification from the api
 * @param null null
 * @returns Observable
 *
 */
get(): Observable<any> {
  return this.httpClient.get(this.API_ENDPOINT).pipe(
    map(QualificationTypesService.extractData));
}

/**
 * get a qualification by from the api
 * @param id : string
 * @returns Observable
 *
 */
find(id): Observable<any> {
  return this.httpClient.get(this.API_ENDPOINT + '/' + id).pipe(
    map(QualificationTypesService.extractData));
}

/**
 * create aqualification
 * @param qualificationtypes : Object
 * @returns Observable
 *
 */
create(qualificationtypes: any): Observable<any> {
  console.log('It here', qualificationtypes);
  
  return this.httpClient.post<any>(this.API_ENDPOINT, qualificationtypes)
  .pipe(tap((qualificationtypes) => console.log(`added qualificationtypes w/ name=${qualificationtypes}`)),
    catchError(this.handleError<any>('create qualificationtypes'))
  );
}

/**
 * delete a qualificationtypes
 * @param id : string
 * @returns Observable
 *
 */
delete(id): Observable<any> {
  return this.httpClient.delete<any>(this.API_ENDPOINT + '/' + id).pipe(
    tap(_ => console.log(`deleted qualificationtypes id=${id}`)),
    catchError(this.handleError<any>('delete qualificationtypes'))
  );
}

/**
 * update a qualificationtypes
 * @param qualificationtypes : Object
 * @returns Observable
 *
 */
update(qualificationtypes): Observable<any> {
  console.log('quali', qualificationtypes);
  
  return this.httpClient.put(this.API_ENDPOINT + '/' + qualificationtypes.id, qualificationtypes)
  .pipe(tap(response => console.log(`updated qualificationtypes id=${qualificationtypes.id}`)),
    catchError(this.handleError<any>('updatequalificationtypes'))
  );
}

private getValue() {
  return 0;
}

/**
 * Error handler function that handles all the errors
 * @returns Observable
 *
 * @param operation
 * @param result
 */
private handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for qualificationtypes consumption
    console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}


initializeFormGroup() {
  return this.form.setValue({
    id        : '',
    qualification_type_name : ''
    
  });
}

populateForm(data) {
  this.form.patchValue(data);
}

compareObjects(o1, o2) {
  return o1 && o2 && o1.id === o2.id;
}

}
