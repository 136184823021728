<div fxLayout="row" fxLayoutAlign="space-between" class="mat-dialog-header">
    <div class="header-title align-self-center" style="margin-top: 2.5%;">
        <h2>{{advisorQualificationService.form.controls['id'].value? "Update Qualification":"Add New Qualification"}}</h2>
    </div>
    <div class="header-title align-self-center">
      <button (click)="onClose()" mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
<div class=""
         fxLayout="row"
         fxLayout.xs="column"
         fxFlexFill
         fxLayoutAlign="center center">
  <form [formGroup]="advisorQualificationService.form" 
        class="form"
        fxFlex="500px"
        (ngSubmit)="submitForm()">
    
    <mat-divider></mat-divider>
    <mat-dialog-content>

      <mat-form-field appearance="outline">
        <mat-label>Select Qualification</mat-label>
        <mat-select formControlName="qualification_type_id">
          <mat-option *ngFor="let q of qualificationTypes" [value]="q.id">
            {{q.qualification_type_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="dialog-content" fxLayout="column" fxLayoutGap="5px">
        <mat-form-field appearance="outline">
            <mat-label>Enter Institution Name</mat-label>
          <input matInput
                 formControlName="institution_name"
                 placeholder="Enter Institution Name">
          <mat-error *ngIf="advisorQualificationService.form.controls['institution_name'].errors?.required">This field is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Programme</mat-label>
          <input matInput
                 formControlName="programme"
                 placeholder="Enter Programme">
          <mat-error *ngIf="advisorQualificationService.form.controls['programme'].errors?.required">This field is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Duration (months)</mat-label>
          <input matInput
                 formControlName="duration"
                 placeholder="Enter Duration">
          <mat-error *ngIf="advisorQualificationService.form.controls['duration'].errors?.required">This field is required</mat-error>
        </mat-form-field>
        <div fxLayout="row" fxFlex="100%" fxLayoutGap="5px">
          <app-file-upload *ngIf="!advisorQualificationService.form.controls['attachment'].value"
              title="Upload Your File" fileResourceTypeName="testfile"
              (complete)="fileUploadFn($event)">
          </app-file-upload>
          <small *ngIf="!advisorQualificationService.form.controls['attachment'].value" color="warn"><strong>Upload Certified Copy of Certificates(pdf)</strong> (Max: 5MB)</small>
          <small *ngIf="advisorQualificationService.form.controls['attachment'].value" color="warn"><strong>{{ advisorQualificationService.form.controls['attachment'].value?.fileName }}</strong></small>
          <div *ngIf="advisorQualificationService.form.controls['attachment'].value" fxLayout="column">
              <div>
                  <div fxFlex="40%" style="padding-top: 0.7%">
                      <a target="_blank" matTooltip="Preview this File"
                      [href]="getFilePath(advisorQualificationService.form.controls['attachment'].value?.id)">
                      <mat-icon>attachment</mat-icon><span><h4>Preview</h4></span>
                      </a>
                  </div>
                  <div fxFlex="4%">
                      <span style="cursor: pointer;" matTooltip="Remove this file" mat-icon-button (click)="removeFile()">
                          <mat-icon color="warn">cancel</mat-icon>
                      </span>
                  </div>
              </div>
              
          </div>
        </div>
      </div>
      <!--<pre>{{ data | json }}</pre>-->
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end stretch">
      <div class="button">
        <button mat-raised-button type="button" (click)="onReset()">Reset</button>
        <button mat-raised-button color="primary" [disabled]="advisorQualificationService.form.invalid" type="submit">
          {{advisorQualificationService.form.controls['id'].value? "UPDATE":"CREATE"}}
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>


