import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentService } from 'src/app/admin/Setup/document/document.service';
import { environment } from 'src/environments/environment';
import { AttachmentService } from '../attachment.service';

export const BASE_URL: string = environment.BASE_URL;


@Component({
  selector: 'app-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.scss']
})
export class AttachmentDialogComponent implements OnInit {

  public attachments = {};

  constructor(
    public attachmentService: AttachmentService,
    private dialogRef: MatDialogRef<AttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public documentService:DocumentService,
  ){ }
  documents: any = [];
  docInfo: any;
  doc: any;
  visible: boolean = false;
  arrayData: any = [];

  ngOnInit() {
    this.attachments = JSON.parse(localStorage.getItem("UserAttachments")) ? JSON.parse(localStorage.getItem("UserAttachments")) : {};
    if(Object.keys(this.data).length == 0){
      this.documentService.getRemainingDocs().subscribe((res) => {
        this.documents = res.data
      });
    }else{
    this.documentService.get().subscribe((res) => {
      this.documents = res.data;
      });
    }
  }

  submitForm() {
    const data = this.attachmentService.form.value;
    if (this.attachmentService.form.valid) {
      if (!this.attachmentService.form.get('id').value) {
        this.attachmentService.create(data).subscribe(response => {
          this.onClose();
        });
      } else {
        this.attachmentService.update(data).subscribe(data => {
          this.onClose();
        });
      }
      this.onClose();
    }
  }

  onClose() {
    this.attachmentService.form.reset();
    this.attachmentService.initializeFormGroup();
    this.dialogRef.close();
    this.dialogRef.afterClosed()
      .subscribe(response => {
      });
  }

  onReset() {
    this.attachmentService.form.reset();
    this.attachmentService.initializeFormGroup();
  }
  fileUploadFn(attachment: any) {
    this.attachments[+this.attachmentService.form.get('document_id').value] = attachment.id;
    console.log("AttachmentUploaded", this.attachments);
    this.attachmentService.form.patchValue({ attachment });
    localStorage.setItem("UserAttachments", JSON.stringify(this.attachments));
  }
  
  removeFile() {
    this.attachmentService.form.patchValue({ attachment: null });
    this.attachments["attachment_id"] = null;
    console.log("AttachmentUploaded", this.attachments);
  }
  
  getFilePath(resourceId) {
    return BASE_URL + '/file-resources/download/' + resourceId;
  }

  onSelectEvent(value: any){
    this.documentService.find(value.value)
    .subscribe((res) => {this.docInfo = "Enter " + res.data.document_information
    this.doc = res.data.document_information
      }
      );
    this.visible = true;
  }

}
