
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 
import { AuthGuardService } from './admin/auth/auth-guard.service';
import { ConfirmRegistrationComponent } from './pages/confirm-registration/confirm-registration.component';

import { NotFoundComponent } from './pages/not-found/not-found.component';

export const routes: Routes = [
    { 
        path: '',
        loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule), 
        data: { breadcrumb: 'Sign In ' }
    },
    {
        path: 'reset-password', loadChildren: () => import('./admin/reset-password/reset-password.module').then(m => m. ResetPasswordModule),
        data: { breadcrumb: 'Resetpassword' }
    
    },

    {
        path: 'verify/:id', component: ConfirmRegistrationComponent,
        data: { breadcrumb : 'Confirm Registration'}
    },
    
    { 
        path: 'contact', 
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), 
        data: { breadcrumb: 'Contact' } 
    },
    { 
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
        data: { breadcrumb: 'hOME' }  
        
    },
    { 
        path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuardService]

    },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            initialNavigation: 'enabled',  // for one load page, without reload
            useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }