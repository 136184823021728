<div class="top-menu" *ngIf="currentUser">
    <div>
        <a mat-button [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger">
            <mat-icon class="mat-icon-sm account-icon">person</mat-icon> 
            <span fxShow="false" fxShow.gt-sm>{{ 'ACCOUNT' | translate }}</span> 
            <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
        </a>
   
    <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account">
        <span (mouseleave)="accountMenuTrigger.closeMenu()">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">                   
                <img src="assets/images/others/user.jpg" alt="user-image" width="50">
                <p>{{ currentUser.first_name }} {{ currentUser.last_name }} <br> <small>{{ currentUser.email }} <span *ngIf="settings.rtl">&rlm;</span></small></p>
            </div>
            <div class="divider"></div>
            <a mat-menu-item routerLink="/account"> 
                <mat-icon class="mat-icon-sm">settings</mat-icon>
                <span>Account Settings</span> 
            </a>
           
            <a mat-menu-item routerLink="/"> 
                <mat-icon class="mat-icon-sm">help</mat-icon>
                <span>Help</span> 
            </a>
            <div class="divider"></div>
            <a (click)="logout()" mat-menu-item routerLink="/"> 
                <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                <span>{{ 'LOGOUT' | translate }}</span> 
            </a>
        </span>
    </mat-menu>   
    </div> 

</div>  