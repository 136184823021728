import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styles: [
  ],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class FileUploadComponent implements OnInit {

  /** Name/Title of the file */
  @Input() name: string;
  /** Default tags */
  @Input() tags: string;
  /** Default typename */
  @Input() fileResourceTypeName: string;
  /** If to add name while uploading */
  @Input() inputName: boolean;
  /** If to add tags while uploading */
  @Input() inputTags: boolean;
  /** if upload multiple files */
  @Input() multiple: boolean;
  /** Files already uploaded */
  @Input() uploaded: any;
  public files: Array<FileUploadModel> = [];
  /** File type to accept */
  @Input() accept = '.pdf,image/*';
  /** File upload url */
  url = '/api/v1/file-resources/upload';

  /** Event fired with file file resource object to parent component after successfully upload */
      // tslint:disable-next-line:no-output-native
  @Output() complete = new EventEmitter<any>();

  filess: any;

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    
    fileUpload.onchange = () => {      
      
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push(
            { data: file,
              name: this.name,
              state: 'in',
              inProgress: false,
              progress: 0,
              canRetry: false,
              canCancel: true });
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  private uploadFile(file: FileUploadModel) {

    const fd = new FormData();
    fd.append('fileToUpload',  file.data );

    const req = new HttpRequest('POST', this.url, fd, {
      reportProgress: true,
     // headers: headers
    });

    file.inProgress = true;
    file.sub = this.http.request(req).pipe(
        map(event => {
          
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(event.loaded * 100 / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap(message => { }),
        last(),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          file.canRetry = true;
          return of(`${file.data.name} upload failed.`);
        })
    ).subscribe(
        (event: any) => {
          if (typeof (event) === 'object') {
            this.removeFileFromArray(file);
            this.complete.emit({
              id: event.body.id,
              path: event.body.path,
              fileName: event.body.data
            });
          }
        }
    );
  }

  uploadFiles() {
    this.files.forEach(file => {
      this.uploadFile(file);
    });
    /** Clear inputs to allow another upload */
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.value = '';
    this.name = (this.inputName) ? null : this.name;
  }

  cancelFile(file: FileUploadModel) {
    file.sub.unsubscribe();
    this.removeFileFromArray(file);
  }

  retryFile(file: FileUploadModel) {
    this.uploadFile(file);
    file.canRetry = false;
  }


  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }
}
export class FileUploadModel {
  data: File;
  name: string;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  sub?: Subscription;
}
