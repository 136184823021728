import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { getCurrentUser } from 'src/app/shared/Constant';
import { environment } from 'src/environments/environment';
import { User } from '../users/user.model';


export const BASE_URL: string = environment.BASE_URL;
export const RESOURCE_URL = 'login';
export const RESOURCE_URL2 = 'logout';
export const TIRA_USER: string = environment.TIRA_USER;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private API_ENDPOINT = `${BASE_URL}/${RESOURCE_URL}`;
  private API_ENDPOINT2 = `${BASE_URL}/${RESOURCE_URL2}`;

    currentUser: Observable<any>;
    currentUserSubject: BehaviorSubject<any>;

    constructor(
        private httpClient: HttpClient,
        public jwtHelperService: JwtHelperService
    ) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem(TIRA_USER)));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {        
        return this.currentUserSubject.value;
    }

    /**
     * authenticates user given correct credentials
     * @param email
     * @param password : string
     *
     * @returns respose : object
     */
    authenticate(email: string, password: string) {
        const credentials = {password, email};
        return this.httpClient
            .post<any>(this.API_ENDPOINT, credentials)
            .pipe(tap(response => {
                const token = response.token;
                const currentUser = response;
                currentUser.token = token;
                localStorage.setItem(TIRA_USER, JSON.stringify(currentUser));
                this.currentUserSubject.next(currentUser);
            }));
    }

    private static extractData(res: Response) {
        return res || {};
      }

    getLoggedIn(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT).pipe(
            map(AuthService.extractData));
      }

    /**
     * logs user out and destroy local storage
     */
    logout() {
        localStorage.removeItem(TIRA_USER);
        localStorage.removeItem(environment.USER);
        this.logoutApi();
        this.currentUserSubject.next(null);
    }

    logoutApi(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT2).pipe(
          map(AuthService.extractData));
      }


    /**
     * checks if user is logged in
     * @returns true | false
     */
    public isAuthenticated(): boolean {
        const token: any = JSON.parse(localStorage.getItem(TIRA_USER));
        // Check whether the token is expired and return
        // true or false        
        return !this.jwtHelperService.isTokenExpired(token.token);
    }
}
