import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const BASE_URL: string = environment.BASE_URL;
export const RESOURCE_URL = 'qualification';

@Injectable({
  providedIn: 'root'
})
export class AdvisorQualificationService {

  advisorQualifications: Observable<any>;
  advisorQualificationsSubject: BehaviorSubject<any>;
  
    private API_ENDPOINT = `${BASE_URL}/${RESOURCE_URL}`;

    constructor(
        private httpClient: HttpClient,
      ) {
        this.advisorQualificationsSubject = new BehaviorSubject<any>(this.get());
        this.advisorQualifications = this.advisorQualificationsSubject.asObservable();
      }
  
      form = new FormGroup({
        id        : new FormControl(''),
        institution_name : new FormControl(''),
        duration : new FormControl(''),
        programme : new FormControl(''),
        category : new FormControl(''),
        qualificationable_id : new FormControl(''),
        qualification_type_id : new FormControl(''),
        attachment : new FormControl('',[Validators.required]),
      });
    
      /**
       * helper function to extract data since
       * we are not using a type checker in the request
       * @returns Observable
       *
       * @param res
       */
      private static extractData(res: Response) {
        return res || {};
      }
    
      /**
       * get the list of all company advisors from the api
       * @param null null
       * @returns Observable
       *
       */
      get(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT).pipe(
          map(AdvisorQualificationService.extractData));
      }
    
      /**
       * get a company advisor by from the api
       * @param id : string
       * @returns Observable
       *
       */
      find(id): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT + '/' + id).pipe(
          map(AdvisorQualificationService.extractData));
      }
    
      /**
       * create a company advisor
       * @param advisorQualification : Object
       * @returns Observable
       *
       */
      create(advisorQualification: any): Observable<any> {
        return this.httpClient.post<any>(this.API_ENDPOINT, advisorQualification)
        .pipe(tap((advisorQualification) => console.log(`added company advisor w/ name=${advisorQualification}`)),
          catchError(this.handleError<any>('create company advisor'))
        );
      }
    
      /**
       * delete a company advisor
       * @param id : string
       * @returns Observable
       *
       */
      delete(id): Observable<any> {
        return this.httpClient.delete<any>(this.API_ENDPOINT + '/' + id).pipe(
          tap(_ => console.log(`deleted company advisor id=${id}`)),
          catchError(this.handleError<any>('delete company advisor'))
        );
      }
    
      /**
       * update a company advisor
       * @param advisorQualification : Object
       * @returns Observable
       *
       */
      update(advisorQualification): Observable<any> {
        return this.httpClient.put(this.API_ENDPOINT + '/' + advisorQualification.id, advisorQualification)
        .pipe(tap(response => console.log(`updated company advisor id=${advisorQualification.id}`)),
          catchError(this.handleError<any>('update company advisor'))
        );
      }
    
      private getValue() {
        return 0;
      }
    
      /**
       * Error handler function that handles all the errors
       * @returns Observable
       *
       * @param operation
       * @param result
       */
      private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
    
          // TODO: better job of transforming error for company Advisor consumption
          console.log(`${operation} failed: ${error.message}`);
    
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }
    
    
       initializeFormGroup() {
        return this.form.setValue({
          id        : '',
          institution_name : '',
          duration : '',
          programme : '',
          category : '',
          qualificationable_id : '',
          qualification_type_id : '',
          attachment : '',
        });
      }
    
      populateForm(data) {
        this.form.patchValue(data);
      }
    
      compareObjects(o1, o2) {
        return o1 && o2 && o1.id === o2.id;
      } 
}


