<div
    fxLayout="row"
    fxLayoutAlign="space-between center"
>
    <div>
        <h3>List of Attachments</h3>
    </div>

    <button
        *ngIf="!viewSubmitted && !profile"
        mat-raised-button
        color="primary"
        (click)="openDialog()"
    >
        <mat-icon>add</mat-icon>
        Add New
    </button>
</div>
<br>
<div class="mat-elevation-z8">

    <table
        mat-table
        [dataSource]="dataSource"
        matSort
    >

        <!-- Position Column -->
        <ng-container matColumnDef="No">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                No.
            </th>
            <td
                mat-cell
                *matCellDef="let attachment"
            >
                {{dataSource.filteredData.indexOf(attachment)+1}}
            </td>
        </ng-container>

        <!-- Code Column -->
        <ng-container matColumnDef="attachment_name">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Attachment Name
            </th>
            <td
                mat-cell
                *matCellDef="let attachment"
            >
                {{attachment.document.document_name}}
            </td>
        </ng-container>

        <!-- Gfs Code Description Column -->
        <ng-container matColumnDef="description">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                Attachment Description
            </th>
            <td
                mat-cell
                *matCellDef="let attachment"
            >
                {{attachment.file_resource.description}}
            </td>
        </ng-container>

        <!-- Gfs Code Description Column -->
        <ng-container matColumnDef="attachment">
            <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
            >
                File
            </th>
            <td
                mat-cell
                *matCellDef="let attachment"
            >
                <button
                    mat-menu-item
                    aria-label="View Attachment"
                >
                    <a
                        target="_blank"
                        matTooltip="Preview this File"
                        [href]="getFilePath(attachment.file_resource.id)"
                    >
                        <mat-icon>attachment</mat-icon>
                        <span>
                            <h4>Preview</h4>
                        </span>
                    </a>
                    <mat-icon>visibility</mat-icon>
                    <span>View</span>
                </button>
            </td>
        </ng-container>

        <!-- User Action -->
        <ng-container matColumnDef="formActions">
            <th
                mat-header-cell
                *matHeaderCellDef
            >
                Actions
            </th>
            <td
                mat-cell
                *matCellDef="let attachment"
            >
                <button *ngIf="changeDetail" mat-raised-button color="secondary" (click)="openDialog(attachment)">
                    <mat-icon>launch</mat-icon> Change
                </button>
                <button
                    *ngIf="!changeDetail"
                    mat-icon-button
                    color="primary"
                    [matMenuTriggerFor]="menu"
                    [matMenuTriggerData]="{
              id: attachment.id,
              attachment: attachment
            }"
                    aria-label="User actions"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container>
        <mat-menu #menu="matMenu">
            <ng-template
                matMenuContent
                let-id="id"
                let-attachment="attachment"
            >
                <button
                    mat-menu-item
                    aria-label="View Attachment"
                >
                    <a
                        target="_blank"
                        matTooltip="Preview this File"
                        [href]="getFilePath(attachment.file_resource.id)"
                    >
                        <mat-icon>attachment</mat-icon>
                        <span>
                            <h4>Preview</h4>
                        </span>
                    </a>
                    <mat-icon>visibility</mat-icon>
                    <span>View</span>
                </button>
                <button
                    *ngIf="!viewSubmitted"
                    mat-menu-item
                    aria-label="Delete Attachment"
                    (click)="delete(id, attachment)"
                >
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                </button>
            </ng-template>
        </mat-menu>

        <tbody>
            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns;"
            ></tr>
        </tbody>
    </table>
    <mat-paginator
        [pageSizeOptions]="[10, 20, 40]"
        showFirstLastButtons
    ></mat-paginator>
</div>
<br>
<div *ngIf="viewSubmitted">
    <form [formGroup]="form">
        <p>
            <mat-checkbox
                (click)="review()"
                color="primary"
                value="is_analyzed"
                formControlName="is_analyzed"
                [checked]="false"
            >
                Reviewed
            </mat-checkbox>
        </p>
        <mat-hint>
            By clicking the box, you adhere that you've reviewed thoroughly attachment category
        </mat-hint>
    </form>
</div>
