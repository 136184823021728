import {Injectable} from '@angular/core';

import {
    HttpErrorResponse,
    HttpEvent, HttpHandler,
    HttpInterceptor, HttpRequest,
    HttpResponse
} from '@angular/common/http';

import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';

import {AuthService} from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { getCurrentUser } from 'src/app/shared/Constant';

const JwtHelper = new JwtHelperService();

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    currentUser: any;

    constructor(
        private router: Router,
        private authService: AuthService,
        private spinner: NgxSpinnerService,
        protected toastr: ToastrService,
        private dialog: MatDialog,

    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
        if(request.url == "/api/v1/info-public-checklist" && getCurrentUser() !== undefined)
        {
            this.router.navigate(['/admin']);
        }
        this.spinner.show();
        this.authService.currentUser.subscribe(user  => {
            this.currentUser = user || JSON.parse(localStorage.getItem(environment.TIRA_USER));
        });

        if (this.currentUser) {
            const isTokenExpired = JwtHelper.isTokenExpired(this.currentUser.token);
            // check if the token is expired or not
            if (isTokenExpired) {
                this.authService.logout();
                this.router.navigate(['/']);
                this.toastr.success('Your session expired!', 'error', { timeOut: 3000 });
                // this.loginDialogService.openDialog();
            } else {
            request = request.clone({ headers: request.headers.set('Authorization', 'bearer ' + this.currentUser.token) });
         }
        }

        if (request.url.includes('api/v1/verify')) {
            this.toastr.success(
                'Success',
                'Account Activated Successfully!'
              );
            const parameter = this.router.url.split('/')[2]
            if(parameter == 'verify') {
                this.router.navigate(['/admin']);
            }
         }

        if (!request.headers.has('Content-Type') && !request.url.includes('api/v1/file-resources')) {
           request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });

            // if(request.url.includes('api/v1/file-resources')) {
            //     request = request.clone({ headers: request.headers.set('Content-Type', 'multipart/form-data') });

            // } else {
               // request = request.clone({ headers: request.headers.set('Content-Type', 'multipart/form-data') });

            // }
        }
       request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {``
                    this.spinner.hide();
                        switch (request.method) {                        
                            case 'PUT': case 'POST': case 'DELETE':
                                const successMessage = event.body.message ? event.body.message : 'Success';
                                if(event.body.success == false) {
                                    this.toastr.error(successMessage, 'Error', { closeButton: true, disableTimeOut: false, timeOut: 7000 });
                                } else {
                                    this.toastr.success(successMessage, 'Information', { timeOut: 7000 });
                                }
                                break;
                        }
                   
                }
                 return event;
            }),
            catchError((error: HttpErrorResponse) => {
                this.spinner.hide();
                console.log('ERROR', error);
                const errorMessage = error.error ? error.error.message : 'Unknown error';
                this.toastr.error(errorMessage, 'Error', { closeButton: true, disableTimeOut: false, timeOut: 7000 });
                return throwError(error);
            }));
    }

}
