import { getCurrentUser } from 'src/app/shared/Constant';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/admin/auth/auth.service';
import { Settings, AppSettings } from '../../../app.settings';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {
  public currencies = ['USD', 'EUR'];
  public currency:any; 
  @Input("currentUser") currentUser:any;

  public settings: Settings;
  constructor(
    public appSettings:AppSettings, 
    private authService: AuthService,
    public translateService: TranslateService
    ) { 
    this.settings = this.appSettings.settings; 
  } 

  ngOnInit() {  
    this.currency = this.currencies[0];
    this.currentUser = getCurrentUser()?.user;
    if(!this.currentUser) {
      this.authService.getLoggedIn().subscribe(res => {
        this.currentUser = res.data;
      })
    }
  }

  public changeCurrency(currency){
    this.currency = currency;
  } 

  public changeLang(lang:string){ 
    this.translateService.use(lang);   
  } 

  public getLangText(lang){
    if(lang == 'de'){
      return 'German';
    }
    else if(lang == 'fr'){
      return 'French';
    }
    else if(lang == 'ru'){
      return 'Russian';
    }
    else if(lang == 'tr'){
      return 'Turkish';
    }
    else{
      return 'English';
    }
  } 

  logout() {
    this.authService.logout();
    this.currentUser = null;
}

}
