import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const BASE_URL: string = environment.BASE_URL;
export const RESOURCE_URL = 'company-application';
export const RESOURCE_URL2 = 'user-assign-application';
export const RESOURCE_URL3 = 'analyse-application';
export const RESOURCE_URL4 = 'filter-company-application';
export const RESOURCE_URL5 = 'filter-app-customers';
export const RESOURCE_URL6 = 'previous-company-applications';
export const RESOURCE_URL7 = 'filter-previous-application';


@Injectable({
  providedIn: 'root'
})
export class SubmittedApplicationService {

  roless: Observable<any>;
    rolessSubject: BehaviorSubject<any>;
  
    private API_ENDPOINT = `${BASE_URL}/${RESOURCE_URL}`;
    private API_ENDPOINT2 = `${BASE_URL}/${RESOURCE_URL2}`;
    private API_ENDPOINT3 = `${BASE_URL}/${RESOURCE_URL3}`;
    private API_ENDPOINT4 = `${BASE_URL}/${RESOURCE_URL4}`;
    private API_ENDPOINT5 = `${BASE_URL}/${RESOURCE_URL5}`;
    private API_ENDPOINT6 = `${BASE_URL}/${RESOURCE_URL6}`;
    private API_ENDPOINT7 = `${BASE_URL}/${RESOURCE_URL7}`;

    constructor(
        private httpClient: HttpClient,
      ) {
        this.rolessSubject = new BehaviorSubject<any>(this.get());
        this.roless = this.rolessSubject.asObservable();
      }
    
      form = new FormGroup({
        id        : new FormControl(''),
        is_analyzed : new FormControl(false),
      });
    
      /**
       * helper function to extract data since
       * we are not using a type checker in the request
       * @returns Observable
       *
       * @param res
       */
      private static extractData(res: Response) {
        return res || {};
      }
    
      /**
       * get the list of all roless from the api
       * @param null null
       * @returns Observable
       *
       */
      get(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT).pipe(
          map(SubmittedApplicationService.extractData));
      }

      filterByPlayerCategory(playerId: number): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT4+"/"+playerId).pipe(
          map(SubmittedApplicationService.extractData));
      }

      filterByPlayerCategoryAppToCustomers(playerId: number): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT5+"/"+playerId).pipe(
          map(SubmittedApplicationService.extractData));
      }

      getUserForAssignApplication(id): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT2 + '/' + id).pipe(
          map(SubmittedApplicationService.extractData));
      }

      filterPreviousByPlayerCategory(playerId: number): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT7+"/"+playerId).pipe(
          map(SubmittedApplicationService.extractData));
      }
    
      /**
       * get a roles by from the api
       * @param id : string
       * @returns Observable
       *
       */
      find(id): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT + '/' + id).pipe(
          map(SubmittedApplicationService.extractData));
      }
    
      /**
       * create a roles
       * @param roles : Object
       * @returns Observable
       *
       */
      create(roles: any): Observable<any> {
        return this.httpClient.post<any>(this.API_ENDPOINT, roles)
        .pipe(tap((roles) => console.log(`added roles w/ name=${roles}`)),
          catchError(this.handleError<any>('create roles'))
        );
      }

      analyze(data: any): Observable<any> {
        return this.httpClient.post<any>(this.API_ENDPOINT3, data)
        .pipe(tap((analyzed) => console.log(`added analyzed w/ name=${analyzed}`)),
          catchError(this.handleError<any>('create analyzed'))
        );
      }
    
      /**
       * delete a roles
       * @param id : string
       * @returns Observable
       *
       */
      delete(id): Observable<any> {
        return this.httpClient.delete<any>(this.API_ENDPOINT + '/' + id).pipe(
          tap(_ => console.log(`deleted roles id=${id}`)),
          catchError(this.handleError<any>('delete roles'))
        );
      }
    
      /**
       * update a roles
       * @param roles : Object
       * @returns Observable
       *
       */
      update(roles): Observable<any> {
        return this.httpClient.put(this.API_ENDPOINT + '/' + roles.id, roles)
        .pipe(tap(response => console.log(`updated roles id=${roles.id}`)),
          catchError(this.handleError<any>('updateroles'))
        );
      }

      getPreviousApplications(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT6).pipe(
          map(SubmittedApplicationService.extractData));
      }
    
      /**
       * Error handler function that handles all the errors
       * @returns Observable
       *
       * @param operation
       * @param result
       */
      private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
    
          // TODO: better job of transforming error for roles consumption
          console.log(`${operation} failed: ${error.message}`);
    
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }
    
    
     /*  initializeFormGroup() {
        return this.form.setValue({
          id        : '',
          name : '',
          code : '',
          description: '',
          menus: []
        });
      } */
    
     /*  populateForm(data) {
        this.form.patchValue(data);
      } */
    
      compareObjects(o1, o2) {
        return o1 && o2 && o1.id === o2.id;
      }
}
  