import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const BASE_URL: string = environment.BASE_URL;
export const RESOURCE_URL = 'users';
export const RESOURCE2_URL = 'verify';
export const RESOURCE3_URL = 'director-licence';
export const RESOURCE4_URL = 'get-allowed-checklist';
export const RESOURCE5_URL = 'manager-licence';
export const BRELLA_BASE_URL: string = environment.API_BASE_URL2;
export const BPRA_BASE_URL: string = environment.API_BASE_URL3;
export const BRALLA_RESOURCE_URL = 'brela';
export const BPRA_RESOURCE_URL = 'zanz';
export const RESOURCE6_URL = 'users-internal';
export const RESOURCE7_URL = 'users-external';
export const RESOURCE8_URL = 'approval-users';

@Injectable({
  providedIn: 'root'
})export class UsersService {
    users: Observable<any>;
    usersSubject: BehaviorSubject<any>;
  
    private API_ENDPOINT = `${BASE_URL}/${RESOURCE_URL}`;
    private API_ENDPOINT2 = `${BASE_URL}/${RESOURCE2_URL}`;
    private API_ENDPOINT3 = `${BASE_URL}/${RESOURCE3_URL}`;
    private API_ENDPOINT4 = `${BASE_URL}/${RESOURCE4_URL}`;
    private API_ENDPOINT5 = `${BASE_URL}/${RESOURCE5_URL}`;
    private BRELLA_ENDPOINT = `${BASE_URL}/${BRALLA_RESOURCE_URL}`;
    private BPRA_ENDPOINT = `${BASE_URL}/${BPRA_RESOURCE_URL}`;
    private API_ENDPOINT6 = `${BASE_URL}/${RESOURCE6_URL}`;
    private API_ENDPOINT7 = `${BASE_URL}/${RESOURCE7_URL}`;
    private API_ENDPOINT8 = `${BASE_URL}/${RESOURCE8_URL}`;

    constructor(
        private httpClient: HttpClient,
      ) {
        this.usersSubject = new BehaviorSubject<any>(this.get());
        this.users = this.usersSubject.asObservable();
      }
    
      /**
       * helper function to extract data since
       * we are not using a type checker in the request
       * @returns Observable
       *
       * @param res
       */
      private static extractData(res: Response) {
        return res || {};
      }
    
      /**
       * get the list of all users from the api
       * @param null null
       * @returns Observable
       *
       */
      get(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT).pipe(
          map(UsersService.extractData));
      }

      getExternalUsers(pageNumber: number): Observable<any> {
        // const params = new HttpParams().set('page', pageNumber.toString());
        const params = pageNumber ? { per_page: pageNumber } : {};
        return this.httpClient.get<any>(this.API_ENDPOINT7, {params});
      }

      getExternalUsersFilter(data: any): Observable<any> {
        let params = new HttpParams();
        if (data.pageNumber) {
          params = params.set('per_page', data.pageNumber);
          // params = params.set('size', data.pageSize);
        }
        if (data.filter) {
          params = params.set('filter', data.filter);
        }
        return this.httpClient.get<any>(this.API_ENDPOINT7, { params: params });
      }

      getInternalUsersFilter(data: any): Observable<any> {
        let params = new HttpParams();
        if (data.pageNumber) {
          params = params.set('per_page', data.pageNumber);
          // params = params.set('size', data.pageSize);
        }
        if (data.filter) {
          params = params.set('filter', data.filter);
        }
        return this.httpClient.get<any>(this.API_ENDPOINT6, { params: params });
      }

      getInternalUsers(pageNumber: number): Observable<any> {
        // const params = new HttpParams().set('page', pageNumber.toString());
        const params = pageNumber ? { per_page: pageNumber } : {};
        return this.httpClient.get<any>(this.API_ENDPOINT6, {params});
      }

      getTrashed(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT + '/get-users/trashed').pipe(
          map(UsersService.extractData));
      }
      
      directorOfLicence(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT3).pipe(
          map(UsersService.extractData));
      }

      managerOfLicence(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT5).pipe(
          map(UsersService.extractData));
      }
    
      getApprovalUsers(moduleId: number): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT8+"/"+moduleId);
      }
      /**
       * get a user by from the api
       * @param id : string
       * @returns Observable
       *
       */
      find(id): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT + '/' + id).pipe(
          map(UsersService.extractData));
      }

      checklistAllowed(id): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT4 + '/' + id).pipe(
          map(UsersService.extractData));
      }

      activateAccount(id): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT2 + '/' + id).pipe(
          map(UsersService.extractData));
      }

      activateDeactiovateUser(data: any){
        return this.httpClient.post(this.API_ENDPOINT + '/activate-deactivate-user', data);
      }

      verifyBrella(reg_no: string): Observable<any> {
        return this.httpClient.get<any>(this.BRELLA_ENDPOINT + '/' + reg_no)
            .pipe(tap((resp) => console.log(`add data w/ name=${resp}`)),
                catchError(this.handleError<any>(`get brella data`))
            );
      }

      testBpra(reg_no: string): Observable<any> {
        return this.httpClient.get<any>(this.BPRA_ENDPOINT + '/' + reg_no)
            .pipe(tap((resp) => console.log(`add data w/ name=${resp}`)),
                catchError(this.handleError<any>(`get brella data`))
            );
      }
    
      /**
       * create a user
       * @param user : Object
       * @returns Observable
       *
       */
      create(user: any): Observable<any> {
        return this.httpClient.post<any>(this.API_ENDPOINT, user)
        .pipe(tap((user) => console.log(`added user w/ name=${user}`)),
          catchError(this.handleError<any>('create user'))
        );
      }

      sendEmail(user: any): Observable<any> {
        console.log('ReachedHere');
        return this.httpClient.post<any>(this.API_ENDPOINT2, user)
        .pipe(tap((user) => console.log(`added user w/ name=${user}`)),
          catchError(this.handleError<any>('create user'))
        );
      }
    
      /**
       * delete a user
       * @param id : string
       * @returns Observable
       *
       */
      delete(id: number): Observable<any> {
        return this.httpClient.delete<any>(this.API_ENDPOINT + '/' + id).pipe(
          tap(_ => console.log(`deleted user id=${id}`)),
          catchError(this.handleError<any>('delete user'))
        );
      }

  resetPassword(id: number): Observable<any> {
    return this.httpClient.delete<any>(this.API_ENDPOINT + '/reset-user-password/' + id).pipe(
        tap(_ => console.log(`deleted user id=${id}`)),
        catchError(this.handleError<any>('delete user'))
    );
  }

      restore(id: number): Observable<any> {
        return this.httpClient.get<any>(this.API_ENDPOINT + '/trashed/' + id).pipe(
          tap(_ => console.log(`restored user id=${id}`)),
          catchError(this.handleError<any>('restore user'))
        );
      }

      deletePermanent(id: number): Observable<any> {
        return this.httpClient.delete<any>(this.API_ENDPOINT + '/trashed/' + id).pipe(
          tap(_ => console.log(`deleted user id=${id}`)),
          catchError(this.handleError<any>('delete user'))
        );
      }
    

      reset(email): Observable<any> {
        return this.httpClient.delete<any>(this.API_ENDPOINT + '/' + email).pipe(
          tap(_ => console.log(`reset user id=${email}`)),
          catchError(this.handleError<any>('reset userpassword'))
        );
      }
    
      /**
       * update a user
       * @param user : Object
       * @returns Observable
       *
       */
      update(user): Observable<any> {
        return this.httpClient.put(this.API_ENDPOINT + '/' + user.id, user)
        .pipe(tap(response => console.log(`updated user id=${user.id}`)),
          catchError(this.handleError<any>('updateuser'))
        );
      }

      activateUser(user: any): Observable<any> {
        return this.httpClient.post(this.API_ENDPOINT + '/activate-user', user)
        .pipe(tap(response => console.log(`updated user id=${user.id}`)),
          catchError(this.handleError<any>('updateuser'))
        );
      }

      generateKey(id: number): Observable<any> {
        return this.httpClient.get<any>(this.API_ENDPOINT + '/generate-code/' + id).pipe(
          tap(_ => console.log(`generated key for user id=${id}`)),
          catchError(this.handleError<any>('generate key'))
        );
      }
    
      private getValue() {
        return 0;
      }

      internal(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT+'/internal').pipe(
          map(UsersService.extractData));
      }

      external(): Observable<any> {
        return this.httpClient.get(this.API_ENDPOINT+'/external').pipe(
          map(UsersService.extractData));
      }
    
      /**
       * Error handler function that handles all the errors
       * @returns Observable
       *
       * @param operation
       * @param result
       */
      private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
    
          // TODO: better job of transforming error for user consumption
          console.log(`${operation} failed: ${error.message}`);
    
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }

    
      compareObjects(o1, o2) {
        return o1 && o2 && o1.id === o2.id;
      }
} 
