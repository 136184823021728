import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService } from 'src/app/admin/registration/registration.service';
import { UsersService } from 'src/app/admin/users/users.service';

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss']
})
export class ConfirmRegistrationComponent implements OnInit {

  userId: number;

  constructor(
    public router:Router,
    private userService: UsersService
    ) { }

  ngOnInit() {
    this.userId = +this.router.url.split('/')[2]
  }

  public activate(): void { 
    this.userService.activateAccount(this.userId).subscribe(res => {
      console.log('Account Activated Successfully');
      this.router.navigate(['/']);
      
    })
  //   if(this.router.routerState.snapshot.url.includes("/admin")){
  //     this.router.navigate(['/admin']);
  //   }
  //   else{
  //     this.router.navigate(['/']);
  //   } 
  }

}
