<footer class="bg-primary footer">
    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="py-3 border-bottom-mute theme-container">        
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="60" ngClass.xs="mt-2" class="contact-information"> 
            <h3 class="col-title">CONTACT INFORMATION</h3>            
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
                <mat-icon>location_on</mat-icon>
                <span>Box 2987, Plot No. 4/5, Makole street, LAPF Building, 5th floor, Dar es Salaam, Tanzania</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>call</mat-icon>
                <span> +255 737 825 020</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>mail_outline</mat-icon>
                <span>support@tira.go.tz </span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>schedule</mat-icon>
                <span>Mon - Sun / 9:00AM - 8:00PM</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>directions_bus</mat-icon>
                <span>Routes to us</span>
            </p>
        </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright secondary-color theme-container">
        <p ngClass.xs="mt-1">Copyright © 2021 All Rights Reserved</p>
        <p>Designed & Developed by TIRA - Tem</p>
    </div>
</footer>