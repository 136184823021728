<div fxLayout="column">
    <div fxLayout="row" fxLayoutGap="8px">
        <mat-form-field fxFlex="20" *ngIf="inputName">
            <input matInput placeholder="name" [(ngModel)]="name" />
        </mat-form-field>
        <mat-form-field fxFlex="80" *ngIf="inputTags">
            <input matInput * placeholder="tags" [(ngModel)]="tags" />
        </mat-form-field>
        <button type="button" mat-raised-button [disabled]="name === '' || fileResourceTypeName === ''"
            (click)="onClick()">
            <mat-icon>file_upload</mat-icon>
            Upload
        </button>
    </div>
    <ul>
        <li *ngFor="let file of files" [@fadeInOut]="file.state">
            <mat-progress-bar [value]="file.progress"></mat-progress-bar>
            <span id="file-label">
                {{file.data.name}}
                <a title="Retry" style="cursor: pointer;" (click)="retryFile(file)" *ngIf="file.canRetry">
                    <mat-icon>refresh</mat-icon>
                </a>
                <a title="Cancel" style="cursor: pointer;" (click)="cancelFile(file)" *ngIf="file.canCancel">
                    <mat-icon>cancel</mat-icon>
                </a>
            </span>
        </li>
    </ul>
    <input accept="{{accept}}" type="file" id="fileUpload" name="file" [multiple]="multiple" style="display:none;" />
</div>