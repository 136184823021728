import {ErrorStateMatcher} from '@angular/material/core';
import {AbstractControl, FormControl, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {environment} from '../../environments/environment';
import { CurrencyMaskInputMode } from 'ngx-currency';

export const ConstantData = {
    textLimit: '400',
    integerOnly: '[0-9]*',
    invalidEntry: 'Invalid input, only numeric values are allowed',
    dateFormat: 'dd MMM, yyyy',
    emailValidator: Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"),
    phoneValidator: Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
    mDate:new Date(),
};

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
        return (invalidCtrl || invalidParent);
    }
}

export const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem(environment.TIRA_USER) || '{}');
};

export const customCurrencyMaskConfig = {
    align: "left",
    allowNegative: false,
    allowZero: true,
    decimal: ".",
    precision: 0,
    prefix: "",
    suffix: "",
    thousands: ",",
    nullable: true,
    min: null,
    max: null,
    inputMode: CurrencyMaskInputMode.FINANCIAL
};

export const CheckListData = {
    paymentModule: 'PAYMENT_STATUS',
    companyAttachment: 'COMPANY_ATTACHMENTS',
    companyDetail: 'COMPANY_DETAIL',
    branchSalePoint: 'BRANCH_SALES_POINT',
    bankDetails:'BANK_DETAILS',
    companyShares:'COMPANY_SHARES',
    companySubscribers: 'COMPANY_SUBSCRIBERS',
    companyStaff: 'COMPANY_STAFF',
    reinsuranceTreaty: 'REINSURANCE_TREAT',
    companyManagement: 'COMPANY_MANAGEMENT',
    companyAdvisors: 'COMPANY_ADVISORS',
    accreditation: 'ACCREDITATION',
    companyFinancialInterests: 'FINANCIAL_INTEREST',
    companyAssociation: 'COMPANY_ASSOCIATION'
};

export function percentageValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        if (!value) {
            return null;
        }
        return value > 100 || value < 0 ? {percentInvalid:true}: null;
    }
}

export function nonNegativeValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        if (!value) {
            return null;
        }
        return value < 0 ? {numberInvalid:true}: null;
    }
}

export function nidaValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
        const value = String(control.value);
        if (!value) {
            return null;
        }
        
const idLength = value.length;
let idLengthInvalid: boolean; 
let yearInvalid: boolean;
let monthInvalid: boolean;
let dateInvalid: boolean;
let notMarched: boolean;
const year  = parseInt(value.substring(0,4));
const month  = parseInt(value.substring(4,6));
const ndate  = parseInt(value.substring(6,8));
const lastDate :number = new Date(year, month, 0).getDate();
if (idLength == 20)
{
    if (year < 1900 || year > (new Date()).getFullYear()){yearInvalid = true;}

    if (month < 1 || month > 12){monthInvalid = true;}
    if (ndate < 1 || ndate > lastDate){dateInvalid = true;}
notMarched = yearInvalid || monthInvalid || dateInvalid
}else{
    idLengthInvalid = true; 
    notMarched = idLengthInvalid;
}
        return notMarched ? {nidaInvalid:true}: null;
}
}

export function votersIDValidator(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
        const value = control.value;
        if (!value) {
            return null;
        }
        let idInvalid: boolean;
        const idLength = value.length;
        const firstLetter = value.substring(0,1);
        console.log(firstLetter);
    if (idLength == 13){
        if (firstLetter != 'T'){idInvalid = true;}
    }else{
        idInvalid = true;
    }
        return idInvalid? {votersIDInvalid:true}: null;
    }
}